import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import Star from "../../../assets/images/review-star.svg"
import { SubTitle, Text } from "../../core/commonExports"
import { OnDesktop, OnMobile } from "../../DesktopMobileComps"
import useScrollableCards from "./useScrollableCards"

const factsList = [
  {
    icon: <StaticImage src="../../../assets/images/campaigns/facts-icons/totalBusinesses.webp" alt="businesses" loading="lazy" className="fact-icon" />,
    title: "5000+",
    text: "Total businesses are Verak-insured"
  },
   {
    icon: <StaticImage src="../../../assets/images/campaigns/facts-icons/google.webp" alt="google" loading="lazy" className="fact-icon" />,
    title: <>4.7 <Star className="star" /></>,
    text: "Google Reviews",
    onMobile: true
  },
  {
    icon: <StaticImage src="../../../assets/images/campaigns/facts-icons/claimsIcon.webp" alt="claims" loading="lazy" className="fact-icon" />,
    title: "170+",
    text: "Total claims approved by insurers",
    onMobile: true
  },
  {
    icon: <StaticImage src="../../../assets/images/campaigns/facts-icons/claimsIcon.webp" alt="claims" loading="lazy" className="fact-icon" />,
    title: "170+",
    text: "Total claims approved by insurers",
    onDesktop: true,
  },
  {
    icon: <StaticImage src="../../../assets/images/campaigns/facts-icons/google.webp" alt="google" loading="lazy" className="fact-icon" />,
    title: <>4.7 <Star className="star" /></>,
    text: "Google Reviews",
    onDesktop: true
  },
  {
    icon: <StaticImage src="../../../assets/images/campaigns/facts-icons/irdai.webp" alt="irdai" loading="lazy" className="fact-icon" />,
    title: "Certified by",
    text: "IRDAI, Govt. of India"
  },
]

const FactsAboutUs = () => {
  const { handleCarouselDotClick, cardsRef: factsRef, scrollDotsRef } = useScrollableCards({
    totalCards: 4,
    scrollMultiplier: 2.15
  })

  const factItems = factsList.map(fact => {
    const factItem = (
      <FactContainer>
        {fact.icon}
        <Text fontSize="26px" mfontSize="14px" lineHeight="15.5px" mlineHeight="12.5px" fontWeight="bold" desktopStyles={{margin: "20px 0"}} mobileStyles={{margin: "4px 0"}}>
          {fact.title}
        </Text>
        <Text fontSize="16px" mfontSize="10px" lineHeight="22px" mlineHeight="15.5px" fontWeight="500" style={{textAlign: "center", margin: "0"}}>
          {fact.text}
        </Text>
      </FactContainer>
    )
    if (fact.onDesktop) {
      return <OnDesktop style={{width: "auto"}}>{factItem}</OnDesktop>
    } else if (fact.onMobile) {
      return <OnMobile style={{width: "auto"}}>{factItem}</OnMobile>
    } 
    return factItem
  })

  return (
    <>
      <SubTitle fontSize="32px" mfontSize="16px" lineHeight="38px" mlineHeight="24px" fontWeight="700" style={{textAlign: "center"}}>
        Some facts about us
      </SubTitle>
      <FactsContainer ref={factsRef}>
        {factItems}
      </FactsContainer>
      <OnMobile breakpoint="450px" style={{position: "relative", marginTop: "0.5rem"}}>
        <Text mfontSize="10px" mlineHeight="12px" color="rgb(195, 151, 249)" style={{position: "absolute", right: "0", top: "0", textAlign: "right", margin: "0"}}>
          Swipe right &gt;&gt;
        </Text>
        <ScrollDotsContainer ref={scrollDotsRef} style={{display: "flex", justifyContent: "center", gap: "6px"}} onClick={handleCarouselDotClick}>
          <ScrollDots data-id="1" className="scroll-dot selected" />
          <ScrollDots data-id="2" className="scroll-dot" />
          <ScrollDots data-id="3" className="scroll-dot" />
        </ScrollDotsContainer>
      </OnMobile>
    </>
  )
}

const ScrollDotsContainer = styled.div`
  .selected {
    background: #2A2B7F;
  }
`

const ScrollDots = styled.button`
  border: none;
  width: 10px; 
  height: 10px; 
  background: #C397F9; 
  border-radius: 50%;
  cursor: pointer;
  .selected {
    background: #2A2B7F;
  }
`

const FactsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  margin: auto;
  margin-top: 42px;
  width: 500px;
  @media screen and (max-width: 768px) {
    flex-wrap: nowrap;
    overflow: auto;
    width: 600px;
  } 
  @media screen and (max-width: 450px) {
    justify-content: flex-start;
    padding: 0 0.5rem;
    width: min(450px, 100%);
    width: 100%;
    margin-top: 0px;
    gap: 16px;
  }
`

const FactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  border-radius: 12px;
  border-radius: 12px;
  padding: 20px 12.5px;
  width: 230px;
  .fact-icon {
    width: 80px;
    height: 80px;
  }
  @media screen and (max-width: 768px) {
    flex-shrink: 0;
    width: 120px;
    background: #fff;
    padding: 6px 8px;
    .fact-icon {
      width: 45px;
      height: 45px;
    }
    .star {
      width: 15px;
      vertical-align: sub;
    }
  }
`

export default FactsAboutUs